import React, { useState } from 'react';
import { Box, IconButton, makeStyles, Theme } from '@material-ui/core';
import { Button } from 'primereact/button';
import { AppDrawer } from './app-drawer';

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
    menuButtonWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: spacing(1),
        width: 64,
        height: 64,
    },
    icon: {
        fontSize: '2rem',
        marginTop: '5px',
        color: 'var(--text-color-secondary)',
    },
}));

export const MainMenu = () => {
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false);

    return (
        <>
            <div className={classes.menuButtonWrapper}>
                {!drawerOpen && (
                    <Button
                        icon="pi pi-bars"
                        outlined
                        onClick={() => setDrawerOpen(true)}
                        className={classes.icon}
                    />
                )}

                {drawerOpen && (
                    <Button
                        icon="pi pi-times"
                        outlined
                        onClick={() => setDrawerOpen(false)}
                        className={classes.icon}
                    />
                )}
            </div>

            <AppDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
        </>
    );
};
