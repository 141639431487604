import React, { useEffect, useState } from 'react';
import {
    Tree,
    TreeEventNodeEvent,
    TreeExpandedKeysType,
    TreeNodeTemplateOptions,
} from 'primereact/tree';
import { TreeNode } from 'primereact/treenode';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectObjectState } from 'models/objects/object/selectors';
import { selectSectorState } from 'models/objects/sector/selectors';
import { ObjectResponseType } from 'models/objects/object/types';
import { getObjectsList } from 'models/objects/object/actions';
import { getSectorById, getSectorsList } from 'models/objects/sector/actions';
import { SectorResponseType } from 'models/objects/sector/types';
import { WorkgroupResponseType } from 'models/objects/workgroup/types';
import { WeldBookResponseType } from 'models/weldbooks/weldbook/types';
import { formatDate } from 'utils/date';
import { getWeldBookListBySectorId } from 'models/weldbooks/weldbook/actions';
import {
    getAllSectorsListApi,
    getSectorByIdApi,
    getSectorsListApi,
} from 'models/objects/sector/api';
import { getWorkgroupListApi } from 'models/objects/workgroup/api';
import { selectWorkgroupState } from 'models/objects/workgroup/selectors';
import { selectWeldBookState } from 'models/weldbooks/weldbook/selectors';
import { getWeldBookListBySectorIdApi } from 'models/weldbooks/weldbook/api';
import { displayNumberByUser } from 'pages/gas-pipeline/pages/weldbook/forms/helpers';

export const DataTree: React.FC = () => {
    const [nodes, setNodes] = useState<TreeNode[]>([]);
    const [selectedKey, setSelectedKey] = useState<string>('');
    const [expandedKeys, setExpandedKeys] = useState<TreeExpandedKeysType>({});
    const { isTreeUpdate: isObjectTreeUpdate } =
        useAppSelector(selectObjectState);
    const { isTreeUpdate: isSectorTreeUpdate } =
        useAppSelector(selectSectorState);
    const { isTreeUpdate: isWorkGroupTreeUpdate } =
        useAppSelector(selectWorkgroupState);
    const { isTreeUpdate: isWeldBookTreeUpdate } =
        useAppSelector(selectWeldBookState);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const page = window.location.href;

    const applyNodes = async (data: any) => {
        setNodes(data);
    };
    const generateTreeData = async () => {
        const objectsNodes: TreeNode[] = [];
        let objects: ObjectResponseType[] = [];
        let objectIndex: any;
        let sectorIndex: any;
        let workgroupIndex: any;
        let weldbookIndex: any;

        if (page.indexOf('passport') !== -1) {
            const sectorId = page.split('sector_id=')[1];
            const wbList: any = await getWeldBookListBySectorIdApi(sectorId);
            wbList.results
                .sort((a: WeldBookResponseType, b: WeldBookResponseType) => {
                    if (a.number === null) {
                        return 1;
                    } else if (b.number === null) {
                        return -1;
                    } else {
                        return a.number! - b.number!;
                    }
                })
                .forEach((wb: WeldBookResponseType, index: number) => {
                    objectsNodes.push({
                        key: `${index}`,
                        label: `КСС № ${displayNumberByUser(wb) ?? '-'}`,
                        leaf: true,
                        data: {
                            type: 'passport',
                            id: wb.item_uuid,
                            sectorId: wb.sector_item,
                            datetime: wb.datetime,
                        },
                    });
                });
            applyNodes(objectsNodes);
            const weldbookId = page.split('id=')[1].split('&sector_')[0];
            objectsNodes.forEach((wb: TreeNode) => {
                if (wb.data.id === weldbookId) {
                    weldbookIndex = wb.key;
                    setSelectedKey(`${weldbookIndex}`);
                }
            });
        } else {
            await dispatch(getObjectsList())
                .unwrap()
                .then((response: any) => {
                    objects = response.results;
                    objects.forEach(
                        (object: ObjectResponseType, index: number) => {
                            objectsNodes.push({
                                key: `${index}`,
                                label: object.name,
                                leaf: false,
                                children: [],
                                data: {
                                    type: 'object',
                                    id: object.item_uuid,
                                },
                            });
                        }
                    );
                });

            if (
                objectsNodes.length > 0 &&
                page.indexOf('sectors?object_id') !== -1
            ) {
                const objectId = page.split('id=')[1];
                objectIndex = objectsNodes.findIndex(
                    (object: TreeNode) => object.data.id === objectId
                );
                await applyNodes(objectsNodes);
                setExpandedKeys({});
                setSelectedKey(`${objectIndex}`);
            } else if (
                objectsNodes &&
                page.indexOf('weldbook?sector_id') !== -1
            ) {
                const sectorId = page.split('id=')[1];
                const sectorById: any = await getSectorByIdApi(sectorId);
                const sectorList: any = await getSectorsListApi(
                    sectorById.object_item.item_uuid
                );
                objectIndex = objectsNodes.filter(
                    (o: TreeNode) =>
                        o.data.id === sectorById.object_item.item_uuid
                )[0].key;
                sectorList.results
                    .sort(
                        (a: SectorResponseType, b: SectorResponseType) =>
                            a.begin! - b.begin!
                    )
                    .forEach((sector: SectorResponseType, index: number) => {
                        objectsNodes[objectIndex].children!.push({
                            key: `${objectIndex}-${index}`,
                            label: `${Number(sector.begin! / 1000).toFixed(
                                2
                            )} - ${Number(sector.end! / 1000).toFixed(2)}`,
                            leaf: false,
                            children: [],
                            data: {
                                type: 'sector',
                                id: sector.item_uuid,
                            },
                        });
                        if (sectorId === sector.item_uuid) {
                            sectorIndex = `${objectIndex}-${index}`;
                            objectsNodes[objectIndex!].children![
                                index
                            ].expanded = false;
                        }
                    });
                const keysToExpand: TreeExpandedKeysType = {
                    [`${objectIndex}`]: true,
                };
                await applyNodes(objectsNodes);
                setExpandedKeys(keysToExpand);
                setSelectedKey(`${sectorIndex}`);
            } else if (nodes && page.indexOf('weldbook?workgroup_id') !== -1) {
                const sectorId = page.split('sector_id=')[1];
                const workgroupId = page
                    .split('workgroup_id=')[1]
                    .split('&sector_id=')[0];
                const sectorById: any = await getSectorByIdApi(sectorId);
                const sectorList: any = await getSectorsListApi(
                    sectorById.object_item.item_uuid
                );
                objectIndex = objectsNodes.filter(
                    (o: TreeNode) =>
                        o.data.id === sectorById.object_item.item_uuid
                )[0].key;
                sectorList.results
                    .sort(
                        (a: SectorResponseType, b: SectorResponseType) =>
                            a.begin! - b.begin!
                    )
                    .forEach((sector: SectorResponseType, index: number) => {
                        objectsNodes[objectIndex].children!.push({
                            key: `${objectIndex}-${index}`,
                            label: `${Number(sector.begin! / 1000).toFixed(
                                2
                            )} - ${Number(sector.end! / 1000).toFixed(2)}`,
                            leaf: false,
                            children: [],
                            data: {
                                type: 'sector',
                                id: sector.item_uuid,
                            },
                        });
                        if (sectorId === sector.item_uuid) {
                            sectorIndex = `${objectIndex}-${index}`;
                        }
                    });

                const wgList: any = await getWorkgroupListApi(sectorId);

                wgList.results
                    .sort(
                        (a: WorkgroupResponseType, b: WorkgroupResponseType) =>
                            a.name.localeCompare(b.name)
                    )
                    .forEach((wg: WorkgroupResponseType, index: number) => {
                        objectsNodes![objectIndex!].children![
                            sectorIndex.split('-')[1]
                        ].children!.push({
                            key: `${sectorIndex}-${index}`,
                            label: `${wg?.name || ''} - ${wg?.brigadier || ''}`,
                            leaf: true,
                            children: [],
                            data: {
                                type: 'workgroup',
                                id: wg.item_uuid,
                                sectorId: wg.sector_item,
                            },
                        });
                        if (workgroupId === wg.item_uuid) {
                            workgroupIndex = `${sectorIndex}-${index}`;
                        }
                    });
                const keysToExpand: TreeExpandedKeysType = {
                    [`${objectIndex}`]: true,
                    [`${sectorIndex}`]: true,
                };
                await applyNodes(objectsNodes);
                setExpandedKeys(keysToExpand);
                setSelectedKey(`${workgroupIndex}`);
            } else {
                await applyNodes(objectsNodes);
                setSelectedKey('');
                setExpandedKeys({});
            }
        }
    };

    const navigateToPage = (event: TreeEventNodeEvent) => {
        switch (event.node.data.type) {
            case 'object':
                navigate({
                    pathname: '/gas-pipeline/sectors',
                    search: `?object_id=${event.node.data.id}`,
                });
                break;
            case 'sector':
                navigate({
                    pathname: '/gas-pipeline/weldbook',
                    search: `?sector_id=${event.node.data.id}`,
                });
                break;
            case 'workgroup':
                navigate({
                    pathname: '/gas-pipeline/weldbook',
                    search: `?workgroup_id=${event.node.data.id}&sector_id=${event.node.data.sectorId}`,
                });
                break;
            case 'passport':
                navigate({
                    pathname: '/gas-pipeline/passport',
                    search: `?weldbook_id=${event.node.data.id}&sector_id=${event.node.data.sectorId}`,
                });
                break;
            default:
        }
    };

    const nodeTemplate = (node: TreeNode, options: TreeNodeTemplateOptions) => {
        if (node.data.type === 'passport') {
            return (
                <div className="">
                    <div>{node.label}</div>
                    <label className="cursor-pointer">
                        Дата сварки:{' '}
                        {formatDate(node.data.datetime, 'dd.MM.yyyy')}
                    </label>
                </div>
            );
        } else {
            return <span className={options.className}>{node.label}</span>;
        }
    };

    const onExpand = async (event: TreeEventNodeEvent) => {
        const oldNodes: any = [...nodes];
        switch (event.node.data.type) {
            case 'object':
                const sectors: any = await getSectorsListApi(
                    event.node.data.id
                );
                if (sectors.results.length > 0) {
                    oldNodes[Number(event.node.key!)].children = [];
                    sectors.results
                        .sort(
                            (a: SectorResponseType, b: SectorResponseType) =>
                                a.begin! - b.begin!
                        )
                        .forEach(
                            (sector: SectorResponseType, index: number) => {
                                oldNodes[
                                    Number(event.node.key!)
                                ].children!.push({
                                    key: `${event.node.key!}-${index}`,
                                    label: `${Number(
                                        sector.begin! / 1000
                                    ).toFixed(2)} - ${Number(
                                        sector.end! / 1000
                                    ).toFixed(2)}`,
                                    leaf: false,
                                    children: [],
                                    data: {
                                        type: 'sector',
                                        id: sector.item_uuid,
                                    },
                                });
                            }
                        );
                    await applyNodes(oldNodes);
                }

                break;
            case 'sector':
                const workGroups: any = await getWorkgroupListApi(
                    event.node.data.id
                );
                if (workGroups.results.length > 0) {
                    const objectIndex: number = +(
                        event.node.key! as string
                    ).split('-')[0];
                    const sectorIndex: number = +(
                        event.node.key! as string
                    ).split('-')[1];
                    oldNodes[objectIndex].children[sectorIndex].children = [];
                    workGroups.results
                        .sort(
                            (
                                a: WorkgroupResponseType,
                                b: WorkgroupResponseType
                            ) => a.name.localeCompare(b.name)
                        )
                        .forEach((wg: WorkgroupResponseType, index: number) => {
                            oldNodes[objectIndex].children[
                                sectorIndex
                            ].children!.push({
                                key: `${objectIndex}-${sectorIndex}-${index}`,
                                label: `${wg?.name || ''} - ${
                                    wg?.brigadier || ''
                                }`,
                                leaf: true,
                                children: [],
                                data: {
                                    type: 'workgroup',
                                    id: wg.item_uuid,
                                    sectorId: wg.sector_item,
                                },
                            });
                        });
                    await applyNodes(oldNodes);
                }
                break;
            default:
                break;
        }
    };
    // Рендер дерева при изменении веб страницы
    useEffect(() => {
        if (page) {
            generateTreeData();
        }
    }, [page]);
    // Рендер дерева при изменении структуры БД
    useEffect(() => {
        if (
            isObjectTreeUpdate ||
            isSectorTreeUpdate ||
            isWorkGroupTreeUpdate ||
            isWeldBookTreeUpdate
        ) {
            generateTreeData();
        }
    }, [
        isObjectTreeUpdate,
        isSectorTreeUpdate,
        isWorkGroupTreeUpdate,
        isWeldBookTreeUpdate,
    ]);

    return (
        <>
            <div className="card flex justify-content-left ml-2">
                <Tree
                    value={nodes}
                    selectionMode="single"
                    selectionKeys={selectedKey}
                    className={`${
                        nodes.some((n) => n.data.type === 'passport')
                            ? 'navigation-tree-main'
                            : 'navigation-tree'
                    } w-full md:w-30rem mt-0`}
                    onSelect={(event: TreeEventNodeEvent) =>
                        navigateToPage(event)
                    }
                    expandedKeys={expandedKeys}
                    onToggle={(e) => setExpandedKeys(e.value)}
                    filter
                    filterMode="strict"
                    filterPlaceholder="Поиск"
                    nodeTemplate={nodeTemplate}
                    onExpand={onExpand}
                />
            </div>
        </>
    );
};
