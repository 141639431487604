import React, { useRef, useState, useEffect } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectDictSteelGradeState } from 'models/dictionaries/dict-steel-grade/selectors';
import {
    DictSteelGradeBaseType,
    DictSteelGradeResponseType,
} from 'models/dictionaries/dict-steel-grade/types';
import {
    updateDictSteelGrade,
    getDictSteelGradeList,
    createDictSteelGrade,
    deleteDictSteelGrade,
} from 'models/dictionaries/dict-steel-grade/actions';
import { SteelGradeDeletingDialog } from './steel-grade-deleting-dialog';
import { SteelGradeDialog } from './steel-grade-dialog';

export const DictSteelGradeTable = () => {
    const [selectedItem, setSelectedItem] =
        useState<DictSteelGradeResponseType | null>(null);
    const [isSteelGradeDialogOpen, setIsSteelGradeDialogOpen] =
        useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const { dictSteelGradeList, isLoading } = useAppSelector(
        selectDictSteelGradeState
    );
    const dispatch = useAppDispatch();
    const toast = useRef<Toast>(null);

    const columns = [
        {
            title: 'Наименование',
            field: 'value',
            frozen: false,
            sortable: true,
        },
    ];

    const actionTemplate = (row: any) => (
        <div className="flex">
            <Button
                text
                severity="secondary"
                icon="pi pi-pencil"
                className="mr-4 p-0"
                onClick={(event) => handleEditClick(row)}
            />
            <Button
                text
                severity="secondary"
                icon="pi pi-trash"
                className="p-0"
                onClick={(event) => handleDeleteClick(row)}
            />
        </div>
    );

    const rowTemplate = (data: any) => {
        if (!data) {
            return <div className="flex">-</div>;
        } else {
            return <div className="flex">{data}</div>;
        }
    };

    const handleEditClick = (item: DictSteelGradeResponseType) => {
        setSelectedItem(item);
        setIsSteelGradeDialogOpen(true);
    };
    const handleDeleteClick = (item: DictSteelGradeResponseType) => {
        setSelectedItem(item);
        setDeleteDialogOpen(true);
    };

    const handleUpdate = (
        item_uuid: string,
        item: DictSteelGradeResponseType
    ) => {
        dispatch(updateDictSteelGrade({ uuid: item_uuid, body: item }))
            .unwrap()
            .then((res) => {
                toast.current?.show([
                    {
                        severity: 'success',
                        detail: 'Марка стали обновлена',
                        life: 3000,
                    },
                ]);
                dispatch(getDictSteelGradeList()).unwrap();
                setSelectedItem(null);
                setIsSteelGradeDialogOpen(false);
            });
    };
    const handleCreate = (item: DictSteelGradeBaseType) => {
        dispatch(createDictSteelGrade(item))
            .unwrap()
            .then((res) => {
                toast.current?.show([
                    {
                        severity: 'success',
                        detail: 'Марка стали создана',
                        life: 3000,
                    },
                ]);
                dispatch(getDictSteelGradeList()).unwrap();
                setIsSteelGradeDialogOpen(false);
            });
    };

    const handleDelete = (item: DictSteelGradeResponseType) => {
        setDeleteDialogOpen(false);
        dispatch(deleteDictSteelGrade(item.item_uuid))
            .unwrap()
            .then((res) => {
                toast.current?.show([
                    {
                        severity: 'success',
                        detail: 'Марка стали удалена из базы',
                        life: 3000,
                    },
                ]);
                dispatch(getDictSteelGradeList()).unwrap();
                setSelectedItem(null);
            });
    };

    const handleClose = () => {
        setDeleteDialogOpen(false);
        setIsSteelGradeDialogOpen(false);
        setSelectedItem(null);
    };

    useEffect(() => {
        dispatch(getDictSteelGradeList()).unwrap();
    }, []);

    return (
        <div className="tableContainer">
            <Toast ref={toast} />
            <div className="flex justify-content-between align-items-center">
                <p className="block text-2xl font-bold">Марки стали</p>
                <div>
                    <Button
                        color="primary"
                        onClick={() => setIsSteelGradeDialogOpen(true)}
                    >
                        Добавить марку стали
                    </Button>
                </div>
            </div>

            <DataTable
                value={dictSteelGradeList}
                scrollable
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                selectionMode="single"
                rowHover
                emptyMessage="Марки стали пока не добавлены"
                className="mt-2"
            >
                {columns.map((col, i) => (
                    <Column
                        key={col.field}
                        field={col.field}
                        header={col.title}
                        style={{ width: '90%' }}
                        frozen={!!col.frozen}
                        sortable={col.sortable}
                        body={(row) => rowTemplate(row[`${col.field}`])}
                    />
                ))}
                <Column header="" body={actionTemplate} />
            </DataTable>
            {Boolean(isSteelGradeDialogOpen) && (
                <SteelGradeDialog
                    formData={selectedItem}
                    onClose={handleClose}
                    onUpdate={handleUpdate}
                    onCreate={handleCreate}
                />
            )}
            {Boolean(deleteDialogOpen) && (
                <SteelGradeDeletingDialog
                    steelGradeData={selectedItem}
                    onDelete={handleDelete}
                    onClose={handleClose}
                />
            )}
        </div>
    );
};
