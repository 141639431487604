import {
    default as DataTable,
    MaterialTableProps,
    MTableBodyRow,
    MTableToolbar,
} from '@material-table/core';

import { Box, makeStyles, Theme, useTheme } from '@material-ui/core';
import { useState } from 'react';
import { ContextMenu } from '../index';

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
    tableWrapper: {
        '& > div > div:last-of-type > div:first-of-type': {
            // fix dumb header scroll
            overflow: 'hidden',
        },
        '&.MuiTableRow-root.MuiTableRow-hover:hover': {
            backgroundColor: 'inherit',
            outline: `2px solid ${palette.primary.main}`,
            outlineOffset: -2,
        },
        '& td.MuiTablePagination-root': {
            borderBottom: 'none',
        },
    },
    toolbarWrapper: {
        display: 'flex',
        alignItems: 'center',
        // width: '90%',
        flexGrow: 1,
        marginRight: '12%',
        '&>div': {
            flexWrap: 'wrap',
            width: '100%',
        },
    },
    buttonPlaceholder: {
        minWidth: 140,
    },
}));

type IMaterialTableProps<T extends object> = MaterialTableProps<T> & {
    onEdit?: (element: any) => void;
    onDelete?: (element: any) => void;
};

const initialState = {
    mouseX: null,
    mouseY: null,
    row: null,
};

export const MaterialTable = <T extends object>(
    props: IMaterialTableProps<T>
): JSX.Element => {
    const classes = useStyles();

    // todo bug in lib. need to override font-family, remove after update
    //https://github.com/material-table-core/core/issues/124

    const { typography } = useTheme();
    const [contextMenuData, setContextMenuData] = useState<any | null>(
        initialState
    );

    const openContextMenu = (event: any, props: any) => {
        event.preventDefault();
        const data = {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
            row: props.data,
        };
        setContextMenuData(data);
    };

    const handleClose = () => {
        setContextMenuData(initialState);
    };

    const handleEdit = (row: any) => {
        setContextMenuData(initialState);
        props.onEdit?.(row);
    };

    const handleDelete = (row: any) => {
        setContextMenuData(initialState);
        props.onDelete?.(row);
    };

    return (
        <div className={classes.tableWrapper}>
            <DataTable
                {...props}
                options={{
                    ...props.options,
                    // todo fix after bug resolved
                    rowStyle: {
                        ...props.options?.rowStyle,
                        fontFamily: typography.fontFamily,
                        ...typography.body2,
                    },
                    headerStyle: {
                        ...props.options?.headerStyle,
                        backgroundColor: '#FAFAFA',
                        position: 'sticky',
                        top: 0,
                    },
                    draggable: false,
                    emptyRowsWhenPaging: true,
                    doubleHorizontalScroll: true,
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} из {count}',
                        labelRowsSelect: 'элементов',
                        labelRowsPerPage: 'Элементов на странице',
                        firstAriaLabel: 'В начало',
                        firstTooltip: 'В начало',
                        previousAriaLabel: 'Предыдущая страница',
                        previousTooltip: 'Предыдущая страница',
                        nextAriaLabel: 'Следующая страница',
                        nextTooltip: 'Следующая страница',
                        lastAriaLabel: 'В конец',
                        lastTooltip: 'В конец',
                    },
                    toolbar: {
                        nRowsSelected: '{0} выбранных элементов',
                        searchPlaceholder: 'Поиск',
                        addRemoveColumns: 'Выбор колонок для таблицы',
                        showColumnsAriaLabel: 'Выбор колонок',
                        showColumnsTitle: 'Выбор колонок',
                        ...props.localization?.toolbar,
                    },
                    header: {
                        actions: 'Действия',
                    },
                    body: {
                        emptyDataSourceMessage: 'Нет данных',
                        addTooltip: 'Добавить',
                        deleteTooltip: 'Удалить',
                        editTooltip: 'Редактировать',
                        filterRow: {
                            filterTooltip: 'Фильтр',
                        },
                        editRow: {
                            deleteText:
                                'Вы уверены, что хотите удалить эту запись?',
                            cancelTooltip: 'Отмена',
                            saveTooltip: 'Принять',
                        },
                    },
                    grouping: {
                        placeholder:
                            'Перенесите заголовок в область для группировки',
                        groupedBy: 'Группировка по:',
                    },
                }}
                components={{
                    Row: (props) => (
                        <MTableBodyRow
                            {...props}
                            onContextMenu={(event: any) =>
                                openContextMenu(event, props)
                            }
                        />
                    ),
                    Toolbar: (props: any) => (
                        <Box className={classes.toolbarWrapper}>
                            <Box>
                                <MTableToolbar {...props} />
                            </Box>
                        </Box>
                    ),
                }}
            />
            {props.onEdit && props.onDelete ? (
                <ContextMenu
                    data={contextMenuData}
                    editElement={() => handleEdit(contextMenuData.row!)}
                    removeElement={() => handleDelete(contextMenuData.row!)}
                    closeMenu={handleClose}
                />
            ) : null}
        </div>
    );
};
