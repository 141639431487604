import React, { useRef, useState, useEffect } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import {
    createDictRadiographyEquip,
    deleteDictRadiographyEquip,
    getDictRadiographyEquipList,
    updateDictRadiographyEquip,
} from 'models/dictionaries/dict-radiography-equipment/actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { DictRadiographyEquipResponseType } from 'models/dictionaries/dict-radiography-equipment/types';
import { selectDictRadiographyEquipState } from 'models/dictionaries/dict-radiography-equipment/selectors';
import { RadiographyEquipDialog } from './radiography-equipment-dialog';
import { RadiographyEquipDeletingDialog } from './radiography-equipment-deleting-dialog';

export const RadiographyEquipTable = () => {
    const [selectedItem, setSelectedItem] =
        useState<DictRadiographyEquipResponseType | null>(null);
    const [isRadiographyEquipDialogOpen, setIsRadiographyEquipDialogOpen] =
        useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const { dictRadiographyEquipList, isLoading } = useAppSelector(
        selectDictRadiographyEquipState
    );
    const dispatch = useAppDispatch();
    const toast = useRef<Toast>(null);

    const columns = [
        {
            title: 'Схема просвечивания',
            field: 'screening_scheme',
            frozen: false,
            sortable: true,
        },
        {
            title: 'Марка',
            field: 'brand',
            frozen: false,
            sortable: true,
        },
        {
            title: 'Рабочее напряжение',
            field: 'voltage',
            frozen: false,
        },
        {
            emptyValue: '-',
            title: 'Мощность излучения',
            field: 'radiation_power',
            frozen: false,
        },
        {
            title: 'Тип детектора',
            field: 'detector_type',
            frozen: false,
        },
        {
            title: 'Фокусное пятно',
            field: 'focal_spot',
            frozen: false,
        },
    ];

    const handleEditClick = (item: DictRadiographyEquipResponseType) => {
        setSelectedItem(item);
        setIsRadiographyEquipDialogOpen(true);
    };
    const handleDeleteClick = (item: DictRadiographyEquipResponseType) => {
        setSelectedItem(item);
        setDeleteDialogOpen(true);
    };

    const actionTemplate = (row: any) => (
        <div className="flex">
            <Button
                text
                severity="secondary"
                icon="pi pi-pencil"
                className="mr-4 p-0"
                onClick={(event) => handleEditClick(row)}
            />
            <Button
                text
                severity="secondary"
                icon="pi pi-trash"
                className="p-0"
                onClick={(event) => handleDeleteClick(row)}
            />
        </div>
    );

    const handleUpdate = (
        item_uuid: string,
        item: DictRadiographyEquipResponseType
    ) => {
        dispatch(updateDictRadiographyEquip({ uuid: item_uuid, body: item }))
            .unwrap()
            .then((res) => {
                toast.current?.show([
                    {
                        severity: 'success',
                        detail: 'Средство контроля обновлено',
                        life: 3000,
                    },
                ]);
                dispatch(getDictRadiographyEquipList()).unwrap();
                setSelectedItem(null);
                setIsRadiographyEquipDialogOpen(false);
            });
    };
    const handleCreate = (item: DictRadiographyEquipResponseType) => {
        dispatch(createDictRadiographyEquip(item))
            .unwrap()
            .then((res) => {
                toast.current?.show([
                    {
                        severity: 'success',
                        detail: 'Средство контроля создано',
                        life: 3000,
                    },
                ]);
                dispatch(getDictRadiographyEquipList()).unwrap();
                setIsRadiographyEquipDialogOpen(false);
            });
    };

    const handleDelete = (item: DictRadiographyEquipResponseType) => {
        setDeleteDialogOpen(false);
        dispatch(deleteDictRadiographyEquip(item.item_uuid))
            .unwrap()
            .then((res) => {
                toast.current?.show([
                    {
                        severity: 'success',
                        detail: 'Средство контроля удалено из базы',
                        life: 3000,
                    },
                ]);
                dispatch(getDictRadiographyEquipList()).unwrap();
                setSelectedItem(null);
            });
    };

    const handleClose = () => {
        setDeleteDialogOpen(false);
        setIsRadiographyEquipDialogOpen(false);
        setSelectedItem(null);
    };

    useEffect(() => {
        dispatch(getDictRadiographyEquipList()).unwrap();
    }, []);

    return (
        <div className="tableContainer">
            <Toast ref={toast} />
            <div className="flex justify-content-between align-items-center">
                <p className="block text-2xl font-bold">Средства контроля</p>
                <div>
                    <Button
                        color="primary"
                        onClick={() => setIsRadiographyEquipDialogOpen(true)}
                    >
                        Добавить средство контроля
                    </Button>
                </div>
            </div>

            <DataTable
                value={dictRadiographyEquipList}
                scrollable
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                selectionMode="single"
                rowHover
                emptyMessage="Средства контроля пока не добавлены"
                className="mt-2"
            >
                {columns.map((col, i) => (
                    <Column
                        key={col.field}
                        field={col.field}
                        header={col.title}
                        style={{ minWidth: '100px' }}
                        frozen={!!col.frozen}
                        sortable={col.sortable}
                    />
                ))}
                <Column header="" body={actionTemplate} />
            </DataTable>
            {Boolean(isRadiographyEquipDialogOpen) && (
                <RadiographyEquipDialog
                    formData={selectedItem}
                    onClose={handleClose}
                    onUpdate={handleUpdate}
                    onCreate={handleCreate}
                />
            )}
            {Boolean(deleteDialogOpen) && (
                <RadiographyEquipDeletingDialog
                    equipmentData={selectedItem}
                    onDelete={handleDelete}
                    onClose={handleClose}
                />
            )}
        </div>
    );
};
