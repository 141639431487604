import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';

type AppContainerPropsType = {
    children: React.ReactElement;
};

const useStyles = makeStyles(() => ({
    mainContainer: {
        width: 'inherit',
        flex: 1,
        maxWidth: 'unset',
    },
}));

export const AppContainer: React.FC<AppContainerPropsType> = ({ children }) => {
    const classes = useStyles();
    const [currentPage, setCurrentPage] = useState<string>('');
    const location = useLocation();
    const navigate = useNavigate();

    // при каждом переходе записываем значение текущей страницы и сраниваем с предыдущей.
    // если предыдущая страница - редактор, а текущая - host приложение, то делаем релоад
    useEffect(() => {
        if (
            location.pathname.indexOf('editor') === -1 &&
            currentPage.indexOf('editor') !== -1
        ) {
            navigate(0);
        }
        setCurrentPage(location.pathname);
    }, [location]);
    return (
        <Container component="div" className={classes.mainContainer}>
            {children}
        </Container>
    );
};
